import groovy from './groovy.gif';
import './App.css';

// Function to calculate days passed since July 25, 2023
const daysSinceRerun = () => {
    const rerunDate = new Date('July 25, 2023 00:00:00');
    const today = new Date();
    const timeDifference =  today.getTime() - rerunDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
}

function App() {
  return (
      <body>
        <div className="App">
          <header className="App-header">
            <img src={groovy} className="App-logo" alt="logo" />
            <p className="text-rerun">
              Days since 3.8 rerun ended: {daysSinceRerun()}
            </p>
          </header>
        </div>
      </body>
  );
}

export default App;
